import { AddTaskFormTypes } from '@/components/Modals/AddTask/AddTask.model.ts';
import { _notNil } from '@/littledash.ts';
import { TaskOneOffScheduleCreate, TaskRecurringScheduleCreate, TaskSpecCreate } from '@/model/Task.model.ts';
import { UserApiId } from '@/model/User.model.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils.ts';

export interface TaskSpecWrapper {
  id: string;
  taskSpec: TaskSpecCreate;
}

export type InvalidUsersIssue = { type: 'invalid-users'; users: Array<UserApiId> };
export type InvalidGroupsIssue = { type: 'invalid-groups'; groups: Array<string> };
export type InvalidTreatmentsIssue = { type: 'invalid-treatments'; treatments: Array<string> };
export type InvalidMeasurementsIssue = { type: 'invalid-measurements'; measurements: Array<string> };
export type TaskSpecV2Issue =
  | InvalidUsersIssue
  | InvalidGroupsIssue
  | InvalidMeasurementsIssue
  | InvalidTreatmentsIssue;
export type TaskSpecIssueAction =
  | { type: 'close-modal' }
  | { type: 'add-invalid-users'; taskSpecId: string; users: Array<UserApiId>; access: 'read' | 'write' }
  | { type: 'remove-invalid-users'; taskSpecId: string; users: Array<UserApiId>; allTasks: boolean }
  | { type: 'open-task-spec'; taskSpecId: string; tab?: AddTaskFormTypes }
  | { type: 'remove-invalid-groups-and-open'; taskSpecId: string; groups: Array<string> }
  | { type: 'remove-invalid-measurements-and-open'; taskSpecId: string; measurements: Array<string> }
  | { type: 'remove-invalid-treatments-and-open'; taskSpecId: string; treatments: Array<string> };

export const validateTaskSpec = (
  taskSpecs: Array<TaskSpecWrapper>,
  studyUserIds: Set<UserApiId>,
  studyGroupIds: Set<string>,
  treatmentIds: Set<string>,
  measurementIds: Set<string>
): Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>> =>
  taskSpecs.reduce((acc, taskSpec) => {
    const invalidGroupsIssue = validateTaskSpecGroups(taskSpec, studyGroupIds);
    if (_notNil(invalidGroupsIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidGroupsIssue]);
    }
    const invalidUsersIssue = validateTaskSpecUsers(taskSpec, studyUserIds);
    if (_notNil(invalidUsersIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidUsersIssue]);
    }
    const invalidMeasurementsIssue = validateTaskSpecMeasurements(taskSpec, measurementIds);
    if (_notNil(invalidMeasurementsIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidMeasurementsIssue]);
    }
    const invalidTreatmentsIssue = validateTaskSpecTreatments(taskSpec, treatmentIds);
    if (_notNil(invalidTreatmentsIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidTreatmentsIssue]);
    }
    return acc;
  }, new Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>>());

export const convertTaskSpecScheduleToIsoDate = (
  taskSpecSchedule: TaskRecurringScheduleCreate | TaskOneOffScheduleCreate,
  timezone: string
): TaskRecurringScheduleCreate | TaskOneOffScheduleCreate => {
  if (!DateUtils.isValidDate(taskSpecSchedule.duration.start)) {
    taskSpecSchedule.duration.start = DateUtils.renderDateTime(taskSpecSchedule.duration.start, {
      format: DateRenderFormat.ISODate,
      timezone: timezone ?? undefined,
    });
  }
  if (!DateUtils.isValidDate(taskSpecSchedule.duration.end)) {
    taskSpecSchedule.duration.end = DateUtils.renderDateTime(taskSpecSchedule.duration.end, {
      format: DateRenderFormat.ISODate,
      timezone: timezone ?? undefined,
    });
  }
  taskSpecSchedule.duration.type = 'date';
  return taskSpecSchedule;
};

const validateTaskSpecUsers = (
  taskSpec: TaskSpecWrapper,
  studyUserIds: Set<UserApiId>
): InvalidUsersIssue | undefined => {
  const invalidUsers = (taskSpec.taskSpec.assignees ?? []).reduce<Array<UserApiId>>(
    (acc, userId) => (studyUserIds.has(userId) ? acc : [...acc, userId]),
    []
  );
  if (invalidUsers.length > 0) {
    return { type: 'invalid-users', users: invalidUsers };
  }
};
const validateTaskSpecGroups = (
  taskSpec: TaskSpecWrapper,
  studyGroupIds: Set<string>
): InvalidGroupsIssue | undefined => {
  if (taskSpec.taskSpec.target.type === 'group') {
    const invalidGroups = taskSpec.taskSpec.target.groups.reduce<Array<string>>(
      (acc, groupId) => (studyGroupIds.has(groupId) ? acc : [...acc, groupId]),
      []
    );
    if (invalidGroups.length > 0) {
      return { type: 'invalid-groups', groups: invalidGroups };
    }
  }
};

const validateTaskSpecMeasurements = (
  taskSpec: TaskSpecWrapper,
  measurementIds: Set<string>
): InvalidMeasurementsIssue | undefined => {
  if (taskSpec.taskSpec.type === 'measurement') {
    const measurements = (taskSpec.taskSpec.execution?.measurement?.measurements ?? []).reduce<Array<string>>(
      (acc, measurementId) => (measurementIds.has(measurementId) ? acc : [...acc, measurementId]),
      []
    );
    if (measurements.length > 0) {
      return { type: 'invalid-measurements', measurements };
    }
  }
};
const validateTaskSpecTreatments = (
  taskSpec: TaskSpecWrapper,
  treatmentIds: Set<string>
): InvalidTreatmentsIssue | undefined => {
  if (taskSpec.taskSpec.type === 'dosing') {
    const treatments = (taskSpec.taskSpec.execution?.dosing?.treatments ?? []).reduce<Array<string>>(
      (acc, treatmentId) => (treatmentIds.has(`${treatmentId}`) ? acc : [...acc, `${treatmentId}`]),
      []
    );
    if (treatments.length > 0) {
      return { type: 'invalid-treatments', treatments };
    }
  }
};
