import { components } from '@/generated/internal-api/openapi-types';
import { ISODateTime } from '@/model/Common.model.ts';

export type BenchlingBackfillApiId = components['schemas']['BenchlingBackfillApiId.schema'];

export interface Backfill {
  api_id: BenchlingBackfillApiId;
  status: components['schemas']['BenchlingBackfillStatus.schema'];
  total_entities: number;
  created_by: components['schemas']['UserApiId.schema'];
  date_from: ISODateTime;
  date_to: ISODateTime;
}

export const backfillStatusRenderMap = {
  completed: 'Completed',
  running: 'Running',
  failed: 'Failed',
  timed_out: 'Timed out',
  completed_with_warnings: 'Completed with warnings',
};

export type BenchlingBackfillV1 = components['schemas']['BenchlingBackfillV1.schema'];
