import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers.tsx';
import { formatNumber } from '@/helpers.tsx';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash.ts';
import { FC, useMemo } from 'react';
import { ConsumptionMetric } from '@/model/Consumption.model.ts';

interface ConsumptionListProps {
  measurements: Array<ConsumptionMetric>;
}

const flattenMeasurements = (measurements: Array<ConsumptionMetric>): Array<ConsumptionMetric> => {
  const groupedMeasurements: Record<string, ConsumptionMetric> = {};
  measurements.forEach((m) => {
    if (!m.created_date) return;
    if (!groupedMeasurements[m.created_date]) {
      groupedMeasurements[m.created_date] = m;
    } else {
      groupedMeasurements[m.created_date].consumption_in = m.consumption_in;
    }
  });
  return Object.values(groupedMeasurements);
};

const ConsumptionList: FC<ConsumptionListProps> = ({ measurements }) => {
  const flattenedMeasurements = useMemo(() => flattenMeasurements(measurements), [measurements]);

  return (
    <Accordion>
      {flattenedMeasurements.map((m) => {
        return (
          <AccordionItem key={m.api_id}>
            <AccordionHeader className="f6 flex flex-wrap justify-between items-center mh3">
              <span className="near-black">
                {m.consumed ? `${formatNumber(m.consumed ?? 0)} g/day` : `${formatNumber(m.consumption_in ?? 0)} g`}
              </span>
              <DateRenderer value={m.created_date} />
            </AccordionHeader>
            <AccordionPanel>
              <div className="ph3 pb3 flex">
                <div className="w-100 pl3">
                  {_isNotEmpty(m) && (
                    <ul className="pa3 br3 br--top--left bg-near-white ma0">
                      {_notNil(m.consumption_out) && <li className="f6 lh-copy">Food Out: {m.consumption_out} g</li>}
                      {_notNil(m.consumption_in) && <li className="f6 lh-copy">Food In: {m.consumption_in} g</li>}
                      {_notNil(m.spillage) && <li className="f6 lh-copy">Spillage: {m.spillage} g</li>}
                      {_notNil(m.population) && <li className="f6 lh-copy">Population: {m.population}</li>}
                      {_notNil(m.consumed) && (
                        <li className="f6 lh-copy">
                          Consumed per Animal: {(m.consumed / m.population).toFixed(2)} g/day
                        </li>
                      )}
                      {m.comment && <li className="f6 lh-copy">Comments: {m.comment}</li>}
                    </ul>
                  )}
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default ConsumptionList;
