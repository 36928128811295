import ApiErrorBanner from '@/components/ApiErrorBanner';
import { checkStudyForDuplicates } from '@/components/Modals/AssignIdentifiers/AssignIdentifiers.utils';
import type { PercentChangeSettings } from '@/components/Modals/SetupWorkflow/SetupWorkflow.model';
import { PercentChangeOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.model';
import ReasonForChangeV7 from '@/components/Shared/ReasonForChangeV7';
import Button from '@/components/UI/Button';
import NumericDelta from '@/components/UI/NumericDelta';
import type { components } from '@/generated/internal-api/openapi-types';
import { defaultPromiseErrorHandler, formatNumber, preventNumberScroll } from '@/helpers';
import { _isEmptyString, _isNil, _isNotBlank, _isNotEmpty, _isNumber, _notNil } from '@/littledash';
import type { AltId, Animal, AnimalApiId } from '@/model/Animal.model';
import type { Measurement, MeasurementApiId } from '@/model/Measurement.model';
import { Preset } from '@/model/Preset.model.ts';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import { PresetCalculationMeasurement } from '@/model/PresetCalculationMeasurement.model';
import { State } from '@/model/State.model';
import type { StudyApiId } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import { useRequest } from '@/support/Hooks/request';
import useAudioNotifications from '@/support/Hooks/useAudioNotifications';
import { AlertService } from '@/utils/alerts/useAlert';
import { useDevices } from '@/utils/devices/useDevices';
import { compileFormula } from '@/utils/FormulaEngine.ts';
import { ErrorMessage } from '@hookform/error-message';
import type { AxiosResponse } from 'axios';
import { EvalFunction } from 'mathjs';
import React, { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm, useFormState, useWatch } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
import useMeasurementComparator from '../useMeasurementComparator';
import type { RenderFunctions } from '../useMeasurementComparator/useMeasurementComparator.model';
import { autoSwap, isFormDisabled } from './Form.utils';

interface FormComponentProps {
  studyId: StudyApiId;
  preset: Preset;
  subject: Animal;
  editing: boolean;
  settings: { measured_at: string; measuring: Array<string> };
  onMeasurementSubmit: (measurementResponse: unknown, editing: boolean) => void;
  refetchAnimal?: () => void;
  todaysMeasurement?: Measurement;
  cageName: string;
  disabled?: boolean;
  measurements?: Array<Measurement>;
  percentChange?: PercentChangeSettings;
  playMeasurementNoise?: boolean;
  assignAltId?: boolean;
  altIdToAssign?: AltId;
  setUnsavedMeasurements?: (unsaved: boolean) => void;
  cursorPosition?: string;
}

interface MeasurementFieldProps {
  assignAltId?: boolean;
  index: number;
  editing: boolean;
  submitting: boolean;
  disabled?: boolean;
  calc: PresetCalculation;
  defaultValues: any;
  handleKeyPress: KeyboardEventHandler<HTMLInputElement>;
  formulas: Record<string, number>;
  showDifference: Function; // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  handleDifference: Function; // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  formMethods: any;
  fieldWatch: any;
  onSubmit: any;
  calculations: PresetCalculation[];
  playNotifySound: () => Promise<void>;
  playErrorSound: () => Promise<void>;
  allMeasurementFieldsFilled: boolean;
  cursorPosition?: string;
}

export interface MeasurementFormData {
  fields: Record<string, string>;
  reason_for_change: string;
  alt_id: string;
}

type MeasurementCreateOrUpdateV1 = components['schemas']['MeasurementCreateOrUpdateV1.schema'];
type MeasurementV1 = components['schemas']['MeasurementV1.schema'];

type CreateOrUpdateMeasurementRequest = {
  studyId: StudyApiId;
  animalId: AnimalApiId;
  measurement: MeasurementCreateOrUpdateV1;
  signal: AbortSignal;
  reasonForChange?: string;
} & ({ isUpdate: true; measurementId: MeasurementApiId } | { isUpdate: false });

const createOrUpdateMeasurement = async (request: CreateOrUpdateMeasurementRequest): Promise<MeasurementV1> => {
  if (request.isUpdate) {
    const requestBody = {
      ...request.measurement,
      ...(_isNotEmpty(request.reasonForChange) ? { reason_for_change: request.reasonForChange } : null),
    };
    const updateResponse = await ApiService.call({
      endpoint: 'PATCH /api/v1/studies/{studyId}/animals/{animalId}/measurements/{measurementId}',
      path: { studyId: request.studyId, animalId: request.animalId, measurementId: request.measurementId },
      body: requestBody,
      signal: request.signal,
    });
    if (updateResponse.type === 'success') {
      return updateResponse.body;
    }
    throw updateResponse;
  }
  const createResponse = await ApiService.call({
    endpoint: 'POST /api/v1/studies/{studyId}/animals/{animalId}/measurements',
    path: { studyId: request.studyId, animalId: request.animalId },
    body: request.measurement,
    signal: request.signal,
  });
  if (createResponse.type === 'success') {
    return createResponse.body;
  }
  throw createResponse;
};

const FormComponent: React.FC<FormComponentProps> = ({
  studyId,
  preset,
  subject,
  editing,
  settings: { measured_at, measuring },
  onMeasurementSubmit,
  refetchAnimal,
  todaysMeasurement,
  cageName,
  disabled,
  percentChange,
  playMeasurementNoise,
  measurements,
  altIdToAssign,
  setUnsavedMeasurements,
  cursorPosition,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiError] = useState<unknown | false>(false);
  const measurementComparator: Record<string, RenderFunctions> = useMeasurementComparator({
    measurements: measurements ?? [],
    calculations: preset?.calculations ?? [],
    comparatorDate: subject?.tracking_started_at,
  });

  const { newAbortController: measurementAbortController } = useAbortController();
  const state = useSelector((state: State) => state?.team);
  const formRef = useRef<HTMLFormElement | null>(null);
  const assigningAltIdEnabled = _notNil(altIdToAssign);

  const measuringFields = useMemo(() => {
    return measuring.reduce((acc: Array<PresetCalculation>, m: string) => {
      const calculation = (preset?.calculations ?? []).find((c: PresetCalculation) => m === c.id);
      if (_notNil(calculation)) {
        acc.push(calculation);
      }
      return acc;
    }, [] as Array<PresetCalculation>);
  }, [measuring, preset]);

  const defaultValues = useMemo(() => {
    // Take passed today's measurement and create a set of default values with `measuring fields`
    const existingMeasurements = todaysMeasurement?.variables ?? {};
    return measuringFields.reduce((acc: Record<string, string>, calculation: PresetCalculation) => {
      calculation.measurements?.forEach((measurement) => {
        if (_notNil(existingMeasurements?.[measurement.id])) {
          acc[measurement.id] = `${existingMeasurements[measurement.id]}`;
        }
      });
      return acc;
    }, {});
  }, [todaysMeasurement, measuringFields]);

  const fields = useMemo(() => {
    return measuringFields.reduce<Record<string, string>>((acc: Record<string, string>, v: PresetCalculation) => {
      (v?.measurements ?? []).forEach((measurement: PresetCalculationMeasurement) => {
        acc = {
          ...acc,
          [measurement.id]: defaultValues?.[measurement.id] ?? '',
        };
      });
      return acc;
    }, {});
  }, [measuringFields, defaultValues]);

  const { playError, playNotify } = useAudioNotifications({ enabled: playMeasurementNoise ?? false });

  const formMethods = useForm<MeasurementFormData>({
    defaultValues: {
      fields: defaultValues,
      alt_id: assigningAltIdEnabled ? (subject.alt_ids?.[altIdToAssign] ?? '') : '',
      reason_for_change: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const { control, register, handleSubmit, reset, setValue, getValues, formState, trigger } = formMethods;
  const { errors, dirtyFields } = formState;

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsavedMeasurements?.(isDirty);
  }, [isDirty]);

  const fieldWatch: Record<string, string> = useWatch({ control, name: 'fields' });
  const { formulaList } = useMemo(() => {
    const activeCalculations = new Set([...measuring]);
    const dependencyGraph = Object.entries(preset.dependency_tree).reduce<
      Map<string, { parents: Set<string>; children: Set<string> }>
    >((acc, [id, parents]) => {
      if (!acc.has(id)) {
        acc.set(id, { parents: new Set(parents), children: new Set<string>() });
      }
      parents.forEach((parent) => {
        if (acc.has(parent)) {
          acc.get(parent)?.children.add(id);
        } else {
          acc.set(id, { parents: new Set(), children: new Set<string>([id]) });
        }
      });
      return acc;
    }, new Map());
    const formulaList = (preset.calculations ?? [])
      .reduce<Array<{ id: string; formula: EvalFunction }>>((acc, calculation) => {
        if (activeCalculations.has(calculation.id)) {
          acc.push({ id: calculation.id, formula: compileFormula(calculation.formula) });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if (dependencyGraph.get(a?.id)?.children.has(b?.id)) {
          return -1;
        } else if (dependencyGraph.get(b?.id)?.children.has(a?.id)) {
          return 1;
        }
        return 0;
      });
    return { formulaList, dependencyGraph };
  }, [preset, measuring]);
  const variables = useMemo(
    () =>
      Object.entries(fieldWatch ?? {}).reduce<Record<string, number>>(
        (acc, [key, value]) => ({ ...acc, [key]: _isEmptyString(value) ? NaN : Number(value) }),
        {}
      ),
    [fieldWatch]
  );
  const formulas = useMemo(
    () =>
      (formulaList ?? []).reduce<Record<string, number>>((acc, { id, formula }) => {
        try {
          const result = formula.evaluate({ ...variables, ...acc });
          if (Number.isFinite(result)) {
            return { ...acc, [id]: result };
          }
        } catch (err) {
          // intentionally empty
        }
        return { ...acc, [id]: NaN };
      }, {}),
    [formulaList, variables]
  );

  const onSubmit = async ({
    fields,
    alt_id,
    reason_for_change,
  }: {
    fields: Record<string, string>;
    alt_id?: string;
    reason_for_change?: string;
  }) => {
    playNotify();
    if (!submitting) {
      setSubmitting(true);
      setApiError(false);
      const payload = autoSwap(preset.calculations, fields);

      const variables = Object.entries(payload).reduce<Array<{ name: string; value: `${number}` | null }>>(
        (acc, [name, value]) => {
          if ((defaultValues?.[name] ?? '') === value) {
            return acc;
          }
          if (_isEmptyString(value ?? '')) {
            if (editing) {
              return [...acc, { name, value: null }];
            }
            return acc;
          }
          return [...acc, { name, value: `${value as number}` }];
        },
        []
      );
      let measurementCallbackValue: MeasurementV1 | null = null;
      let newFieldsValue = {};
      let newAltIdValue = '';

      if (_isNotEmpty(variables)) {
        const response = await processMeasurement(variables, reason_for_change);
        if (_notNil(response?.measurementSubmitResponse)) {
          measurementCallbackValue = response.measurementSubmitResponse;
        }
        if (_isNotEmpty(response?.updatedFields)) {
          newFieldsValue = response.updatedFields;
        }
      }

      if (assigningAltIdEnabled) {
        const newAltId = await processAltId(alt_id);
        newAltIdValue = newAltId ?? '';
        refetchAnimal?.();
      }
      onMeasurementSubmit(measurementCallbackValue, editing);
      reset({ fields: newFieldsValue, alt_id: newAltIdValue });
      setSubmitting(false);
    }
  };

  const processMeasurement = async (
    variables: Array<{ name: string; value: `${number}` | null }>,
    reason_for_change?: string
  ): Promise<{ measurementSubmitResponse: MeasurementV1 | null; updatedFields: Record<string, string> } | void> => {
    try {
      if (_isNotEmpty(Object.values(variables))) {
        const measurementId = todaysMeasurement?.api_id;
        const measurementSubmitResponse = await createOrUpdateMeasurement({
          ...(_isNil(measurementId) ? { isUpdate: false } : { isUpdate: true, measurementId }),
          studyId,
          animalId: subject.api_id as AnimalApiId,
          measurement: { variables, measured_at },
          signal: measurementAbortController().signal,
          reasonForChange: reason_for_change,
        });

        const alerts = measurementSubmitResponse?.subject_alerts ?? [];
        if (_isNotEmpty(alerts)) {
          AlertService.createAlerts({
            alerts,
            calculations: preset.calculations,
            animalName: subject.name ?? '',
            studyId: subject.study_id,
            cageName,
          });
        }
        const updatedFields = Object.keys(fields).reduce<Record<string, string>>(
          (acc, field) => ({
            ...acc,
            [field]: measurementSubmitResponse?.variables?.[field] ?? acc[field],
          }),
          {}
        );
        if (!alerts.some((alert) => alert.notification === 'critical')) {
          setSubmitting(false);
        }
        return { measurementSubmitResponse: measurementSubmitResponse ?? null, updatedFields };
      }
    } catch (apiError) {
      if (notAborted(apiError)) {
        setApiError(apiError);
      }
    }
  };

  const { sendRequest: updateAltId } = useRequest({
    route: 'animals.identifiers.bulk.update',
    method: 'patch',
  });

  const processAltId = async (alt_id?: string) => {
    try {
      if (assigningAltIdEnabled && _notNil(alt_id)) {
        const payload = {
          animals: [{ alt_ids: { ...subject.alt_ids, [altIdToAssign]: alt_id }, id: subject.id, name: subject.name }],
        };
        const updatedSubject = (await updateAltId(payload)) as AxiosResponse<{
          data: Array<Animal>;
        }>;
        return updatedSubject.data?.data[0].alt_ids?.[altIdToAssign];
      }
    } catch (ex) {
      if (notAborted(ex)) {
        setApiError(ex);
      }
    }
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    // #9 = Tab / #13 = Enter
    if (event.keyCode === 13 || event.keyCode === 9) {
      event.preventDefault();
      playNotify();
      const index = Array.prototype.indexOf.call(formRef.current, event.target);
      if (event.shiftKey) {
        (formRef.current?.elements?.[index ? index - 1 : 0] as HTMLFormElement)?.focus();
      } else {
        const allFields = getValues();
        const fields = { ...allFields.fields };
        if (assigningAltIdEnabled) {
          fields.alt_id = allFields.alt_id;
        }
        if (_notNil(fields)) {
          const measurementsComplete = Object.values(fields).every((field) => _isNotEmpty(field));
          const assignIdsSet = index + 1 === Object.keys(fields).length + (_notNil(altIdToAssign) ? 1 : 0);

          // Dirty fix for alt id not being a measurement field
          if ((measurementsComplete || assignIdsSet) && !submitting) {
            handleSubmit(onSubmit)().catch(defaultPromiseErrorHandler);
          } else {
            (formRef.current?.[index + 1] as HTMLFormElement)?.focus();
          }
        }
      }
    }
  };

  const handleDifference = (measurementKey: string): number | null => {
    const comparator = measurementComparator?.[measurementKey];
    const value = _notNil(fieldWatch?.[measurementKey]) ? Number(fieldWatch?.[measurementKey]) : null;
    switch (percentChange?.[measurementKey]) {
      case PercentChangeOptions.fromFirstMeasurement:
        return comparator?.fromFirst(value) ?? null;
      case PercentChangeOptions.fromLatestMeasurement:
        return comparator?.fromLatest(value) ?? null;
      case PercentChangeOptions.trackingDate:
        return comparator?.comparatorDate(value) ?? null;
      default:
        return null;
    }
  };

  const showDifference = (calcId: string): boolean =>
    _notNil(percentChange?.[calcId]) &&
    _isNotBlank(fieldWatch?.[calcId]) &&
    _isNumber(handleDifference(calcId)) &&
    _isNil(todaysMeasurement?.variables?.[calcId]) &&
    handleDifference(calcId) !== 0;

  const allMeasurementFieldsFilled = Object.values(fields).every((f) => f !== '');
  const altIdFieldFilled = altIdToAssign ? _isNotBlank(subject.alt_ids?.[altIdToAssign]) : false;

  const submitDisabled = isFormDisabled({
    submitting,
    editing,
    altIdFieldFilled,
    allMeasurementFieldsFilled,
    assignAltId: _notNil(altIdToAssign),
  });

  const { errors: stateErrors } = useFormState(formMethods);

  const onNewReading = async (response: string) => {
    if (assigningAltIdEnabled && !altIdFieldFilled) {
      setValue('alt_id', response);
      await trigger('alt_id');
      if (stateErrors?.alt_id) {
        playError();
      } else {
        playNotify();
      }
      const isReadingComplete = !preset.calculations.some((calculation) => {
        return calculation.measurements?.some((measurement) => {
          if (_notNil(measurement.id)) {
            return _isEmptyString(getValues(`fields.${measurement.id}`));
          }
          return false;
        });
      });

      nextReading(true);

      if (isReadingComplete) {
        handleSubmit(onSubmit)().catch(defaultPromiseErrorHandler);
      }
    }
  };
  const { nextReading } = useDevices({
    targetId: 'assign-id',
    onNewReading,
  });

  const rules: { required?: string; validate?: (value: string) => Promise<boolean | string> } = {};
  if (assigningAltIdEnabled && altIdToAssign === 'tag' && state?.features?.assign_identifiers_tag_unique === true) {
    rules.required = 'This field is required';
    rules.validate = async (value) => {
      try {
        if (_notNil(subject.api_id)) {
          const result = await checkStudyForDuplicates(value, 'tag', studyId, subject.api_id);
          return result ? true : 'This identifier is already in use';
        }
        return 'Animal not found';
      } catch (error) {
        return 'An error occurred';
      }
    };
  }
  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit, playError)}>
      <>
        {apiError ? (
          <ApiErrorBanner
            className="mw6 mb4"
            error={apiError}
            title="There was an error with your submission"
            text="Please correct the form errors below and try again. If this error persists try again later or contact support."
          />
        ) : null}
        {assigningAltIdEnabled ? (
          <div className="pb3 mt2 bb b--moon-gray">
            <label>{altIdToAssign.charAt(0).toUpperCase() + altIdToAssign.slice(1)}</label>
            <input
              {...register('alt_id', rules)}
              type="text"
              autoComplete="off"
              autoFocus
              onFocus={({ target }) => target.select()}
              disabled={_notNil(subject.alt_ids?.[altIdToAssign])}
              onKeyDown={handleKeyPress}
              className={`${errors?.alt_id ? 'input__error' : ''}`}
              style={{ marginBottom: 0 }}
              data-testid={`workflow-measurement-fields-${altIdToAssign}`}
            />
            <ErrorMessage
              errors={errors}
              name="alt_id"
              render={({ message }) => (
                <p className="f6 red db lh-copy pt1" data-testid={`workflow-measurement-fields-error-${altIdToAssign}`}>
                  {message}
                </p>
              )}
            />
          </div>
        ) : null}
        {measuringFields.map((calc, index) => (
          <MeasurementField
            key={calc.id}
            index={index}
            calc={calc}
            editing={editing}
            defaultValues={defaultValues}
            submitting={submitting}
            disabled={disabled}
            handleKeyPress={handleKeyPress}
            formulas={formulas}
            showDifference={showDifference}
            handleDifference={handleDifference}
            assignAltId={_notNil(altIdToAssign)}
            formMethods={formMethods}
            fieldWatch={fieldWatch}
            onSubmit={onSubmit}
            calculations={preset.calculations}
            playErrorSound={playError}
            playNotifySound={playNotify}
            allMeasurementFieldsFilled={allMeasurementFieldsFilled}
            cursorPosition={cursorPosition}
          />
        ))}
        <div className="pt3">
          <Button submit disabled={submitDisabled} testId="workflow-measurement-save">
            Save
          </Button>
        </div>
      </>
    </form>
  );
};

const MeasurementField: React.FC<MeasurementFieldProps> = ({
  index,
  calc,
  editing,
  defaultValues,
  submitting,
  disabled,
  handleKeyPress,
  formulas,
  showDifference,
  handleDifference,
  assignAltId,
  formMethods,
  fieldWatch,
  onSubmit,
  playErrorSound,
  playNotifySound,
  allMeasurementFieldsFilled,
  cursorPosition,
}) => {
  const { handleSubmit, register, setValue, getValues, formState, trigger, watch } = formMethods;
  const { errors, isSubmitting } = formState;

  const isWeight = calc.id === 'weight';
  const isWeightNotAllRequired = (isWeight && calc.measurements_not_always_required) ?? false;

  const checkMeasurementComplete = () => {
    const { fields } = getValues();
    const complete = Object.values(fields).every((field) => _isNotEmpty(field));

    if (complete && !isSubmitting) {
      handleSubmit(onSubmit)().catch(defaultPromiseErrorHandler);
    }
  };

  const { errors: errorState } = useFormState(formMethods);
  const onNewReading = (response: string) => {
    if (!editing && allMeasurementFieldsFilled) {
      // If there are no measurements to be captured no need to proceed further.
      return;
    }

    calc.measurements?.some((measurement) => {
      const measurementValue = getValues(`fields[${measurement.id}]`);
      if (!measurementValue) {
        setValue(`fields[${measurement.id}]`, response);
        trigger(`fields[${measurement.id}]`).then(() => {
          const errors = errorState as FieldErrors<MeasurementFormData>;
          if (errors?.fields?.[measurement.id]) {
            playErrorSound();
          } else {
            playNotifySound();
          }
        });
        return true;
      }
      return false;
    });

    const isReadingComplete = !calc.measurements?.some((measurement) => {
      const measurementValue = getValues(`fields[${measurement.id}]`);
      return _isEmptyString(measurementValue);
    });
    nextReading(isReadingComplete);
    checkMeasurementComplete();
  };
  const { nextReading } = useDevices({
    targetId: calc.id,
    onNewReading,
    onDeviceError: playErrorSound,
  });

  return (
    <FormProvider {...formMethods}>
      <div className="bb b--moon-gray pv3" key={index}>
        {calc?.measurements?.map((m, i) => {
          const fieldsNotRequired = calc?.measurements_not_always_required ?? false;
          const isDisabled = (!editing && defaultValues[m.id]) || submitting || disabled;
          const focus = _notNil(cursorPosition)
            ? calc.id === cursorPosition && calc.measurements?.[0]?.id === m.id
            : !assignAltId && index === 0 && i === 0;

          return (
            <div className="pb3" key={i}>
              <label>
                {m.name} {_isEmptyString(m?.unit ?? '') ? '' : `(${m.unit})`}
              </label>
              <input
                autoFocus={focus}
                type="number"
                onWheel={preventNumberScroll}
                step="any"
                {...register(`fields.${m.id}`, {
                  required: !fieldsNotRequired && 'This field is required',
                  pattern: {
                    value: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
                    message: 'Must be a valid number',
                  },
                  validate: (value: any) => {
                    const validateFieldWatch = watch('fields');

                    const weightLessThanOrEqualToZero = isWeight && !isWeightNotAllRequired && value <= 0;
                    const weightNotAllRequiredLessThanOrEqualToZero =
                      isWeightNotAllRequired && value !== '' && value <= 0;

                    if (weightLessThanOrEqualToZero || weightNotAllRequiredLessThanOrEqualToZero) {
                      return 'Must be greater than 0';
                    }
                    if (Object.values(validateFieldWatch).every((val) => val === '')) {
                      return 'At least one value must be entered';
                    }
                    return true;
                  },
                })}
                disabled={isDisabled}
                className={`${errors?.fields?.[m.id] ? 'input__error' : ''}`}
                onKeyDown={handleKeyPress}
                style={{ marginBottom: 0 }}
                data-testid={`workflow-measurement-fields-${m.id}`}
              />
              <ErrorMessage
                errors={errors}
                name={`fields.${m.id}`}
                render={({ message }) => (
                  <p className="f6 red db lh-copy pt1" data-testid={`workflow-measurement-fields-error-${m.id}`}>
                    {message}
                  </p>
                )}
              />
            </div>
          );
        })}
        <div className="pt2">
          <label>
            {calc.name} {calc.unit && `(${calc.unit})`}
          </label>
          <div className="flex items-center">
            <h3
              data-tooltip-id="global-tooltip-id"
              data-tooltip-content={calc.formula}
              data-testid={`workflow-measurement-${calc.id}-calculation`}
              className="f3 normal near-black pa2 ph3 lh-title bg-near-white br2 dib"
            >
              {formulas[calc.id] ? formatNumber(formulas[calc.id]) : '-'}
            </h3>
            {showDifference(calc.id) && (
              <NumericDelta value={fieldWatch[calc.id]} valueChange={String(handleDifference(calc?.id))} />
            )}
          </div>
        </div>
        {editing && <ReasonForChangeV7 />}
      </div>
    </FormProvider>
  );
};

export default FormComponent;
