import { _isNotEmpty } from '@/littledash.ts';
import { sortDescByDateStrProperty } from '@/utils/array.ts';
import { FC } from 'react';
import ConsumptionList from '@/components/Workflow/Show/Views/Consumption/ConsumptionList.tsx';
import ConsumptionGraph from '@/components/Workflow/Show/Views/Consumption/ConsumptionGraph.tsx';
import { ConsumptionMetric } from '@/model/Consumption.model.ts';

interface ConsumptionMeasurementsProps {
  cageConsumptionData: Array<ConsumptionMetric>;
}

const ConsumptionMeasurements: FC<ConsumptionMeasurementsProps> = ({ cageConsumptionData }) => {
  const orderedMeasurements = cageConsumptionData.sort(sortDescByDateStrProperty('created_at'));
  const filteredMeasurements = orderedMeasurements.filter((m: ConsumptionMetric) => m.consumed !== null);

  return _isNotEmpty(filteredMeasurements) ? (
    <div>
      <div className="flex flex-wrap justify-between items-center pa3"></div>
      <div className="mb4 ph3">
        <ConsumptionGraph measurements={filteredMeasurements} />
      </div>
      <div className="bt bb b--moon-gray" data-test-key="measurements-list">
        <ConsumptionList measurements={orderedMeasurements} />
      </div>
    </div>
  ) : (
    <div className="bb b--moon-gray">
      <ConsumptionList measurements={orderedMeasurements} />
    </div>
  );
};

export default ConsumptionMeasurements;
