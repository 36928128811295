import Header from '@/components/UI/Header';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { RouteService } from '@/support/RouteService';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateUtils } from '@/utils/Date.utils.ts';
import { backfillStatusRenderMap, BenchlingBackfillV1 } from '@/model/BenchlingBackfill.model.ts';

const BenchlingBackfill: FC = () => {
  const settings = useSelector<{ ui: { settings: Record<string, any> } }>((state) => state.ui.settings);
  const deviceTableColumns: Columns<BenchlingBackfillV1> = useMemo(
    () => [
      {
        id: 'created_at',
        Header: 'Start date',
        accessor: 'created_at',
        sortDisabled: false,
        isVisible: true,
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => <>{DateUtils.renderDateTime(created_at, { defaultResponse: '-' })}</>,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        sortDisabled: true,
        isVisible: true,
        Cell: ({
          row: {
            original: { status },
          },
        }) => <>{backfillStatusRenderMap[status]}</>,
      },
      {
        id: 'completed_at',
        Header: 'Date completed',
        accessor: 'completed_at',
        sortDisabled: false,
        isVisible: true,
        Cell: ({
          row: {
            original: { completed_at },
          },
        }) => <>{DateUtils.renderDateTime(completed_at, { defaultResponse: '-' })}</>,
      },
    ],
    []
  );

  return (
    <>
      <Header mainHeaderText="Benchling sync" />
      <p className="f6 pb2">
        Perform a data backfill to sync historical data between your apps.
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="dib ml1 link blue"
          href="https://help.benchling.com/hc/en-us/articles/22038958660749" // TODO: Update link
        >
          Read more
        </a>
      </p>
      <div className="mv3">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/backfill',
              path: undefined,
            }).url,
          }}
          columns={deviceTableColumns}
          defaultSortBy={{ id: 'created_at', desc: true }}
          settings={settings}
          reduxTableName="benchlingBackfill"
          hideSearch
          hideFilter
        />
      </div>
    </>
  );
};

export default BenchlingBackfill;
