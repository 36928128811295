import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Filters from '@/components/UI/Filters';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import TableFilterMenu from '@/components/UI/SelectDropDown/Menus/TableFilterMenu';
import WorkflowAlerts from '@/components/Workflow/Show/WorkflowAlerts';
import { animalIdsRecord } from '@/constants/utils';
import { infoToast, removeParamsFromUrl, trunc } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _noop, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { Cage } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import * as Auth from '@/support/auth';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { isClosed } from '@/support/study';
import { AlertService, AlertUpdateEvent } from '@/utils/alerts/useAlert';
import { DateUtils } from '@/utils/Date.utils';
import { FC, ReactNode, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { Dispatch } from 'redux';
import AnimalSelectPanel from './AnimalSelectPanel';
import EmptyWorkflow from './EmptyWorkflow';
import { assembleWorkflowFilterOptions, generateFilterParams, reducer } from './Show.utils';
import type { AlertState, ShowState, ShowStateReducer } from './Workflow.model';
import './Workflow.scss';
import WorkflowStage from './WorkflowStage';
import { WorkflowShowProps } from './WorkflowTemplate.utils';
import { TaskApiId } from '@/model/Task.model.ts';
import { RiCalendarLine } from 'react-icons/ri';
import Tooltip from '@/components/UI/Tooltip';
import { useChangeAnimalActions, useSelectedAnimal, useSelectedCage } from '@/utils/workflow/useWorkflow.tsx';
import WorkflowConsumption from '@/components/Workflow/Show/WorkflowConsumption.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors';

const consumptionStateSelector = createSelector([featuresSelector], (features) => ({
  consumptionEnabled: features?.consumption ?? false,
}));

const Show: FC<WorkflowShowProps> = ({ templateDefaults }) => {
  const location = useLocation();
  const { id: studyId } = useParams<{ id: string }>();
  const [filterParams, setFilterParams] = useState<string>('');
  const [taskApiIds, setTaskApiIds] = useState<Array<TaskApiId>>(
    () => new URLSearchParams(location.search).getAll('taskApiId') as Array<TaskApiId>
  );
  const [subjects, setSubjects] = useState<Animal[]>([]);
  const [subjectsLoading, setSubjectsLoading] = useState<boolean>(true);
  const { consumptionEnabled } = useSelector(consumptionStateSelector);

  const alertInitialState: AlertState = useMemo(
    () => ({
      total: 0,
      animalAlerts: {},
    }),
    []
  );

  const initialState: ShowState = useMemo(
    () => ({
      workflow: [],
      filters: [],
      measured_at: templateDefaults?.measured_at ?? DateUtils.dateNow(),
      showGroups: templateDefaults?.showTreatmentGroups ?? false,
      collections: [],
      subjects: [],
      originalSubjects: [],
      deceased: [],
      filterMatch: true,
      checklist: {},
      filterOptions: [],
      searchQuery: '',
      idToSearch: templateDefaults?.idToSearch ?? 'name',
      idToDisplay: templateDefaults?.idToDisplay ?? 'name',
      startTab: templateDefaults?.startOn ?? 'measurements',
      playSound: templateDefaults?.playSound,
      measurements: templateDefaults?.measurements,
      samples: templateDefaults?.samples,
      observations: templateDefaults?.observations,
      dosing: templateDefaults?.dosing,
      alerts: alertInitialState,
    }),
    [alertInitialState]
  );

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users,metadata,study_groups',
  });
  const { collection: collections, collectionLoading: collectionsLoading } = useFetchCollection<Cage>({
    collectionType: 'studyCages',
    params: { studyId },
    includes: 'animals_count',
    queryParams: { perPage: 1000, sort: 'catalog', order: 'asc' },
  });

  const alertUpdateHandler = (event: AlertUpdateEvent) => {
    if (_isNotEmpty(event.detail)) {
      dispatch({ type: 'updateAlerts', data: event.detail });
    }
  };

  const fetchSubjects = async () => {
    if (_notNil(study) && _notNil(study?.api_id)) {
      const response = await ApiService.call({
        endpoint: 'GET /api/v1/studies/{studyId}/animals',
        path: { studyId: study.api_id },
        query: {
          include: ['alerts', 'terminated_at_data', 'metadata'],
          perPage: 1000,
          sort: 'cage',
          order: 'asc',
          ...(_isNotEmpty(taskApiIds) && { task_ids: [...taskApiIds] }),
        },
        filters: filterParams.replaceAll('[]', '%5B%5D').replaceAll('|', '%7C'),
      });
      if (response.type === 'success') {
        // @ts-expect-error: OAI animal does not match manually created animal
        setSubjects(response.body.data);
      }
      setSubjectsLoading(false);
    }
  };

  useEffect(() => {
    if (study || filterParams) {
      fetchSubjects();
    }
  }, [study, filterParams, taskApiIds]);

  useEffect(() => {
    AlertService.addEventListener('alert-resolved', alertUpdateHandler, { passive: true });
    AlertService.addEventListener('alert-deferred', alertUpdateHandler, { passive: true });
    return () => {
      AlertService.removeEventListener('alert-resolved', alertUpdateHandler);
      AlertService.removeEventListener('alert-deferred', alertUpdateHandler);
    };
  }, [alertUpdateHandler]);

  const hasAccess = study && Auth.isWriteUserForStudy(study);

  const [state, dispatch] = useReducer<ShowStateReducer>(reducer, initialState);
  const searchBarRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (_notNil(subjects)) {
      dispatch({ type: 'resetAlerts', data: alertInitialState });
      dispatch({ type: 'setSubjects', data: subjects });
      const animalAlerts = subjects.flatMap((subject) => subject?.alerts ?? []);
      if (_isNotEmpty(animalAlerts)) {
        dispatch({ type: 'updateAlerts', data: animalAlerts });
      }
    }
  }, [subjects]);

  useEffect(() => {
    if (_notNil(collections)) {
      dispatch({ type: 'setCollections', data: collections });
    }
  }, [collections]);

  useEffect(() => {
    if (study && collections) {
      const filterOptions = assembleWorkflowFilterOptions({
        study,
        collections,
      });
      dispatch({ type: 'setFilterOptions', data: filterOptions });
    }
  }, [study, collections]);

  const { alerts, filterOptions, filterMatch, filters, workflow, searchQuery } = state;
  const { selectedAnimal } = useSelectedAnimal();
  const { selectedCage } = useSelectedCage();
  const { changeAnimal } = useChangeAnimalActions();
  const displayFoodConsumption: boolean = useMemo(
    () => consumptionEnabled && _isNil(selectedAnimal) && _notNil(selectedCage) && !collectionsLoading,
    [consumptionEnabled, selectedAnimal, selectedCage, collectionsLoading]
  );

  const storeDispatch = useDispatch();
  const openModal = (modal: string, props: unknown) => {
    storeDispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const openFlash = (res: ReactNode) => {
    toast.success(res);
  };

  const confirmUnarchive = (study: Study, storeDispatch: Dispatch) => {
    Http.post(apiRoute('studies.unarchive', { id: study.id }))
      .then(({ data }) => {
        storeDispatch({ type: 'CLOSE_MODAL' });
        toast.success(`Successfully ${study.archived_at ? 'resumed' : 'completed'} ${study.name}`);
        setupWorkflow();
        storeDispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(_noop);
  };

  const confirmUncancel = (study: Study, storeDispatch: Dispatch) => {
    Http.post(apiRoute('studies.uncancel', { id: study.id }))
      .then(({ data }) => {
        storeDispatch({ type: 'CLOSE_MODAL' });
        toast.success(`Successfully ${study.canceled_at ? 'resumed' : 'cancelled'} ${study.name}`);
        setupWorkflow();
        storeDispatch({
          type: 'SET_CURRENT_STUDY',
          study: data.data,
        });
      })
      .catch(_noop);
  };

  const focusSearch = () => {
    if (_notNil(searchBarRef.current)) {
      searchBarRef.current?.focus();
      searchBarRef.current?.select();
      searchBarRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const setupWorkflow = () => {
    if (hasAccess) {
      openModal('SETUP_WORKFLOW', {
        study,
        state,
        dispatch,
        handleCallback: () =>
          window.setTimeout(() => {
            storeDispatch({ type: 'CLOSE_MODAL' });
            if (focusSearch) {
              focusSearch();
            }
          }, 100),
      });
    }
  };

  useEffect(() => {
    if (!studyLoading && study && !isClosed(study)) {
      setupWorkflow();
    }
  }, [studyLoading]);

  const FilterMenu = () => (
    <TableFilterMenu
      filterOptions={filterOptions as Array<{ name: string; value: string }>}
      filters={filters}
      filterMatch={filterMatch}
      onUpdateFilters={updateFilters as () => void}
    />
  );

  const OpenWorkflowBtn = () => (
    <Button plain onClick={() => setupWorkflow()} testId="workflow-setup-button">
      Workflow Setup
    </Button>
  );

  const searchPlaceholderText = animalIdsRecord[state.idToSearch]?.title ?? 'Name';

  const updateFilters = (filters: Array<Record<string, string | number>>, filterMatch: boolean) => {
    setFilterParams(generateFilterParams(filters, filterMatch));
    dispatch({
      type: 'update',
      data: {
        filters,
        filterMatch,
      },
    });
  };

  if (studyLoading || collectionsLoading || subjectsLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }
  return (
    <>
      {hasAccess ? (
        <>
          {study && (
            <div className="bg-white relative w-100 flex flex-column vh-100 overflow-auto">
              {study.archived_at ? (
                <>
                  <h2 className="lh-title basier-reg normal f4 f3-l pb3 pa4 pb0-l">Workflow</h2>
                  <div className="pa4">
                    <Banner info className="mw6 mr4 mb4" dismiss={false}>
                      <h3 className="normal lh-title f5 pb1">
                        {`This study was completed on ${DateUtils.renderDateTime(study.archived_at)}`}
                      </h3>
                      <p className="f6 lh-copy pb3">
                        You can't setup a workflow for a study that has been completed. To resume this study please
                        select below.
                      </p>
                      <Button
                        className="plain"
                        onClick={() =>
                          openModal('ARCHIVE_STUDY', {
                            study,
                            onClick: () => confirmUnarchive(study, storeDispatch),
                            closeModal: () => storeDispatch({ type: 'CLOSE_MODAL' }),
                          })
                        }
                      >
                        Resume Study
                      </Button>
                    </Banner>
                  </div>
                </>
              ) : study.canceled_at ? (
                <>
                  <h2 className="lh-title basier-reg normal f4 f3-l pb3 pa4 pb0-l">Workflow</h2>
                  <div className="pa4">
                    <Banner info className="mw6 mr4 mb4" dismiss={false}>
                      <h3 className="normal lh-title f5 pb1">
                        {`This study was cancelled on ${DateUtils.renderDateTime(study.canceled_at)}`}
                      </h3>
                      <p className="f6 lh-copy pb3">
                        You can't setup a workflow for a study that has been cancelled. To resume this study please
                        select below.
                      </p>
                      <Button
                        className="plain"
                        onClick={() =>
                          openModal('CANCEL_STUDY', {
                            study,
                            onClick: () => confirmUncancel(study, storeDispatch),
                            closeModal: () => storeDispatch({ type: 'CLOSE_MODAL' }),
                          })
                        }
                      >
                        Resume Study
                      </Button>
                    </Banner>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-100 pv3 ph4 bb b--moon-gray">
                    <div className="flex justify-between w-100">
                      <div className="flex search-study-container">
                        <SearchFilterBar
                          FilterMenu={FilterMenu}
                          searchPlaceholderText={`Search by ${searchPlaceholderText}`}
                          searchQuery={searchQuery}
                          handleSearchInput={(searchQuery) => {
                            focusSearch();
                            dispatch({
                              type: 'searchQuery',
                              data: {
                                searchQuery,
                                changeAnimal,
                              },
                            });
                          }}
                          autoFocus={true}
                          disabled={_isEmpty(state.workflow)}
                          searchBarRef={searchBarRef}
                          autoComplete={false}
                          style={{ minWidth: '20rem' }}
                        />
                        <div className="flex items-center ph3">
                          <h3 className="f6 black lh-title mr3" style={{ whiteSpace: 'initial', textWrap: 'nowrap' }}>
                            {trunc(study?.name ?? '', 50)}
                          </h3>
                          {alerts.total > 0 && (
                            <WorkflowAlerts filters={filters} updateFilters={updateFilters} alertCount={alerts.total} />
                          )}
                        </div>
                      </div>
                      <div className="flex">
                        {_isNotEmpty(taskApiIds) && (
                          <div
                            className="br-100 tc w2 h2 dib bg-washed-blue dark-blue bg-light-gray-hover mt1 mr3"
                            onClick={() => {
                              setTaskApiIds([]);
                              removeParamsFromUrl(['taskApiId', 'type'], location);
                              infoToast('Task animals filter has been removed.');
                            }}
                            data-testid="exit-tasks-execution-button"
                          >
                            <Tooltip render="Exit task execution">
                              <span className="flex h-100 w-100 justify-center items-center pointer">
                                <RiCalendarLine />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        <OpenWorkflowBtn />
                      </div>
                    </div>
                    {_isNotEmpty(filters) && (
                      <Filters filters={filters} filterMatch={filterMatch} updateFilters={updateFilters} />
                    )}
                  </div>
                  <div className="w-100 flex flex-wrap ui__workflow__expand" data-testid="workflow-selection-pane">
                    <AnimalSelectPanel disabled={_isEmpty(state.workflow)} state={state} dispatch={dispatch} />
                    {_notNil(selectedAnimal) && _notNil(selectedCage) && (
                      <WorkflowStage
                        study={study}
                        state={state}
                        dispatch={dispatch}
                        openFlash={openFlash}
                        openModal={openModal}
                        closeModal={() => storeDispatch({ type: 'CLOSE_MODAL' })}
                        focusSearch={focusSearch}
                        openSetupWorkflow={setupWorkflow}
                        fetchSubjects={fetchSubjects}
                      />
                    )}
                    {_isNotEmpty(workflow) && displayFoodConsumption && (
                      <WorkflowConsumption study={study} collections={state.collections} />
                    )}
                    <div className="w-60">
                      {_isEmpty(workflow) && !displayFoodConsumption && (
                        <EmptyWorkflow
                          title="Workflow in Benchling In Vivo"
                          text="Quickly record measurements, observations and collect samples for any animal in your study."
                          openWorkflowBtn={OpenWorkflowBtn}
                        />
                      )}
                      {_isNotEmpty(workflow) && !displayFoodConsumption && (
                        <EmptyWorkflow
                          title="Search or select an animal"
                          text="Quickly record measurements, observations and collect samples for any animal in your study."
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="w-100 h-100 flex items-center justify-center">
          <NoData
            title="You aren't authorized"
            text="Only team members with Write access have access to use Workflow. The study author can update your permissions to give you access."
            link={`/studies/${studyId}/animals/`}
            btnTxt="Return to animals"
          />
        </div>
      )}
    </>
  );
};

export default Show;
