import { Plotly } from '@/support/plotly.ts';
import { useEffect, useRef } from 'react';
import { _isNil, _notNil } from '@/littledash.ts';
import { errorToast } from '@/helpers.tsx';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import InVivoError from '@/model/InVivoError.ts';
import { ConsumptionMetric } from '@/model/Consumption.model.ts';

interface GraphProps {
  measurements: Array<ConsumptionMetric>;
}

const graphSettings = {
  config: {
    responsive: true,
    displayModeBar: false,
  },
  layout: {
    font: {
      family: 'Basier Circle Reg, Arial',
      size: 12,
      color: '#6b6b76',
    },
    hoverlabel: {
      font: {
        family: 'Basier Circle Reg, Arial',
        size: 12,
      },
    },
    margin: {
      l: 24,
      r: 24,
      b: 24,
      t: 0,
    },
    padding: {
      l: 24,
    },
    yaxis: {
      automargin: true,
      ticks: 'inside',
      type: 'linear',
      rangemode: 'tozero',
      showline: true,
      zeroLine: true,
      nticks: 4,
      ticklen: 4,
      linecolor: '#DFE3E8',
      linewidth: 3,
      gridcolor: '#DFE3E8',
      gridwidth: 1,
      tickcolor: '#DFE3E8',
      showexponent: 'all',
      exponentformat: 'e',
      title: {
        text: 'g/day',
        standoff: 12,
        font: {
          family: 'Basier Circle Reg, Arial',
          size: 12,
        },
      },
    },
    xaxis: {
      type: 'date',
      tickformat: '%d %b',
      automargin: true,
      showgrid: false,
      showline: true,
      linecolor: '#DFE3E8',
      linewidth: 3,
      gridcolor: '#DFE3E8',
      gridwidth: 1,
      tickcolor: '#DFE3E8',
    },
  },
  data: [
    {
      line: {
        color: '#0647A6',
        width: 3,
        shape: 'linear',
        simplify: false,
      },
      mode: 'lines+markers',
      x: [] as string[],
      y: [] as number[],
      type: 'scatter',
    },
  ],
};

const getDataPoints = (measurements: Array<ConsumptionMetric>) => {
  return measurements.reduce(
    (acc: { x: string[]; y: number[] }, m) => {
      if (!_isNil(m.consumed)) {
        acc.y.push(m.consumed);
        acc.x.push(m.created_date);
      }
      return acc;
    },
    { x: [], y: [] }
  );
};

const ConsumptionGraph = ({ measurements }: GraphProps) => {
  const graphContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (_notNil(graphContainer.current)) {
      const dataPoints = getDataPoints(measurements);
      const settings = { ...graphSettings };
      settings.data[0].x = dataPoints.x;
      settings.data[0].y = dataPoints.y;
      try {
        Plotly.newPlot(graphContainer.current, {
          data: settings.data,
          config: settings.config,
          layout: settings.layout,
        });
      } catch (cause) {
        errorToast('Could not render graph');
        graphContainer.current.innerText = '';
        ExceptionHandler.captureException(
          new InVivoError('Could not render graph', {
            cause,
            slug: 'consumption-measurement-graph',
            context: { description: 'test' } as Record<string, string>,
          })
        );
      }
    }
  }, [graphContainer, measurements]);

  return (
    <div className="ui__graph" data-testid="foodintake-graph">
      <div ref={graphContainer} className="w-100 h-100" style={{ height: '200px' }} />
    </div>
  );
};

export default ConsumptionGraph;
