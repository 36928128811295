import { taskTypeOptions } from '@/components/Modals/AddTask/AddTask.utils.ts';
import { _first, _isEmptyString } from '@/littledash';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import { TaskSpec, TaskSpecCreate, TaskTimeDuration } from 'model/Task.model';
import {
  AddTaskAssgineeData,
  AddTaskExecutionData,
  AddTaskOverviewData,
  AddTaskScheduleData,
  AddTaskTargetData,
  StepFormPayloadProps,
} from './AddTask.model';
import { conditionTypeOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleConditionalFields';
import { repeatPeriodOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleForm.utils';
import { Nullable } from '@/model/Common.model';

export const taskSpecToOverviewDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskOverviewData => {
  return {
    title: taskSpec?.title ?? '',
    description: taskSpec?.description ?? '',
    type: taskSpec?.type ?? taskTypeOptions[0].value,
  };
};
export const taskSpecToExecutionDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskExecutionData => {
  switch (taskSpec.type) {
    case 'measurement':
      return taskSpec.execution?.measurement ?? {};
    case 'dosing':
      return taskSpec.execution?.dosing ?? {};
    case 'observation':
      return taskSpec.execution?.observation ?? {};
    case 'sample':
      return taskSpec.execution?.sample ?? {};
    default:
      return {};
  }
};

export const taskSpecToAssigneeDefaultValues = (taskSpec: TaskSpec): AddTaskAssgineeData => {
  return (taskSpec?.assignees ?? []).map((user) => user.id);
};

export const taskSpecStudyCreateToScheduleDefaultValues = (
  taskSpec: TaskSpecCreate,
  studyTimezone: Nullable<string>
): AddTaskScheduleData => {
  switch (taskSpec.schedule.type) {
    case 'recurring':
      return {
        type: 'recurring',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration?.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.start ?? '',
          timezone: studyTimezone ?? undefined,
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration?.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.end ?? '',
          timezone: studyTimezone ?? undefined,
        }),
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
        repeat: {
          value: taskSpec.schedule.repeat?.value ?? 0,
          unit: taskSpec.schedule.repeat?.unit ?? repeatPeriodOptions.days.value,
          days: taskSpec.schedule.repeat?.on_day ?? [],
        },
      };
    case 'conditional':
      return {
        type: 'conditional',
        condition: {
          type: taskSpec.schedule.condition?.type ?? _first(conditionTypeOptions).value,
          value: taskSpec.schedule.condition?.value ?? [],
        },
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
      };
    case 'one_off':
      return {
        type: 'one_off',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration?.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.start ?? '',
          timezone: studyTimezone ?? undefined,
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration?.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.end ?? '',
          timezone: studyTimezone ?? undefined,
        }),
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
      };
    default:
      return {
        type: 'one_off',
      };
  }
};

export const taskSpecToScheduleDefaultValues = (taskSpec: TaskSpec): AddTaskScheduleData => {
  switch (taskSpec.schedule.type) {
    case 'recurring':
      return {
        type: 'recurring',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration?.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.start ?? '',
          timezone: taskSpec.schedule.timezone ?? undefined,
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration?.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.end ?? '',
          timezone: taskSpec.schedule.timezone ?? undefined,
        }),
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
        repeat: {
          value: taskSpec.schedule.repeat?.value ?? 0,
          unit: taskSpec.schedule.repeat?.unit ?? repeatPeriodOptions.days.value,
          days: taskSpec.schedule.repeat?.on_day ?? [],
        },
      };
    case 'conditional':
      return {
        type: 'conditional',
        condition: {
          type: taskSpec.schedule.condition?.type ?? _first(conditionTypeOptions).value,
          value: taskSpec.schedule.condition?.value ?? [],
        },
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
      };
    case 'one_off':
      return {
        type: 'one_off',
        start: DateUtils.renderDateTime(taskSpec.schedule.duration?.start, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.start ?? '',
          timezone: taskSpec.schedule.timezone ?? undefined,
        }),
        end: DateUtils.renderDateTime(taskSpec.schedule.duration?.end, {
          format: DateRenderFormat.ISODate,
          defaultResponse: taskSpec.schedule.duration?.end ?? '',
          timezone: taskSpec.schedule.timezone ?? undefined,
        }),
        time: taskSpecToScheduleTimeDefaultValues(taskSpec?.schedule?.time),
      };
    default:
      return {
        type: 'one_off',
      };
  }
};

export const taskSpecToScheduleTimeDefaultValues = (time: Nullable<Partial<TaskTimeDuration>>): TaskTimeDuration => {
  if (time?.type === 'timed') {
    if (_isEmptyString(time?.from ?? '') || _isEmptyString(time?.to ?? '')) {
      return { type: 'all_day' };
    }
    return { type: 'timed', from: time.from as string, to: time.to as string };
  }
  return { type: 'all_day' };
};

export const taskSpecToTargetDefaultValues = (taskSpec: TaskSpec | TaskSpecCreate): AddTaskTargetData => {
  switch (taskSpec.target.type) {
    case 'animal':
      return {
        type: 'animal',
      };
    case 'group':
      return {
        type: 'group',
        groups: taskSpec?.target?.groups ?? [],
      };
    default:
      return {
        type: 'animal',
      };
  }
};

export const editTaskSpecDefaultValues = (taskSpec: TaskSpec): StepFormPayloadProps => {
  return {
    overview: taskSpecToOverviewDefaultValues(taskSpec),
    execution: taskSpecToExecutionDefaultValues(taskSpec),
    assignees: taskSpecToAssigneeDefaultValues(taskSpec),
    schedule: taskSpecToScheduleDefaultValues(taskSpec),
    target: taskSpecToTargetDefaultValues(taskSpec),
  };
};

export const editTaskSpecStudyCreateDefaultValues = (
  studyCreateTaskSpec: TaskSpecCreate,
  studyTimezone: Nullable<string>
): StepFormPayloadProps => {
  return {
    overview: taskSpecToOverviewDefaultValues(studyCreateTaskSpec),
    execution: taskSpecToExecutionDefaultValues(studyCreateTaskSpec),
    assignees: studyCreateTaskSpec.assignees ?? [],
    schedule: taskSpecStudyCreateToScheduleDefaultValues(studyCreateTaskSpec, studyTimezone),
    target: taskSpecToTargetDefaultValues(studyCreateTaskSpec),
  };
};
